<template>
  <ProductCarousel
    :title="_T('@Buy with this product')"
    :products="products"
    :id-tag="'buy-with-product-'"
  />
</template>

<script setup>
import ProductCarousel from "~/modules/shared/sliders/ProductCarousel.vue";
import { useAPI, useAsyncAPI } from "~/uses/useMyFetch";
import { Product } from "~/models/product.model";

const props = defineProps({
  product: { type: Product, required: true },
});

const products = ref(null);
const httpClient = () => {
  return import.meta.client ? useAPI : useAsyncAPI;
};

const fetch = async (httpClient) => {
  return httpClient("/catalog/product/list/reference", {
    params: {
      referenceId: props.product.id,
    },
  }).then((data) => {
    const dtoProducts = data.data ?? data;

    if (0 !== dtoProducts.length) {
      products.value = dtoProducts
        .filter((product) => product.isOrderable)
        .map((product) => new Product(product));
    } else products.value = null;
  });
};

watch(
  () => props.product,
  () => {
    fetch(httpClient());
  },
  { deep: true, immediate: true },
);
</script>
